import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { HomeSectionQueryQuery } from "../../graphql"
import FullScreenComponent from '../full-screen-component'
import FullScreenContainer from '../full-screen-container'
import LuchadorImage from '../luchador-image'
import {scrollTo} from "../../utilities/luchador-utils"
import { aspectRatioMediaQueries, breakpoints, mediaQueries } from "../../styles/mediaQueries"

interface HomeProps {
}

const Home = ({}: HomeProps) => {
    const [active, setActive] = React.useState(undefined)
    const { background, chairman, rolloverGreen, rolloverRed, rolloverWhite, hecho, cans, text }: HomeSectionQueryQuery = useStaticQuery(
        graphql`
            query HomeSectionQuery {
                background: file(
                    relativePath: { eq: "home/HOMEPAGE_BG_N0_CHAIRMAN_x2.jpg"}
                ) {
                    ...backgroundImage
                },
                chairman: file(
                    relativePath: { eq: "home/HOMEPAGE_BG_CHAIRMAN_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1150) {
                            src
                        }
                    }
                },
                rolloverGreen: file(
                    relativePath: { eq: "home/HOMEPAGE_CHAIRMAN_GRN_CAN_4PK_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 214) {
                            src
                        }
                    }
                },
                rolloverRed: file(
                    relativePath: { eq: "home/HOMEPAGE_CHAIRMAN_RED_CAN_4PK_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 214) {
                            src
                        }
                    }
                },
                rolloverWhite: file(
                    relativePath: { eq: "home/HOMEPAGE_CHAIRMAN_WHT_CAN_4PK_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 214) {
                            src
                        }
                    }
                },
                text: file(
                    relativePath: { eq: "home/HOMEPAGE_TEXT_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1383) {
                            src
                        }
                    }
                },
                hecho: file(
                    relativePath: { eq: "home/HOMEPAGE_HECHO_STAMP_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 546) {
                            src
                        }
                    }
                },
                cans: file(
                    relativePath: { eq: "home/HOMEPAGE_3CANS_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1853) {
                            src
                        }
                    }
                },
            }
        `,
    )

    return (
        <StyledHome id="home"  backgroundImage={background.childImageSharp.fluid.src}>
            <div></div>
            <ChairmanContainer>
                <ChairmanImage>
                    <LuchadorImage image={chairman} />
                    <RolloverImage image={rolloverGreen} className={`${active === 0 ? 'active' : 'inactive'}`} />
                    <RolloverImage image={rolloverWhite} className={`${active === 1 ? 'active' : 'inactive'}`} />
                    <RolloverImage image={rolloverRed} className={`${active === 2 ? 'active' : 'inactive'}`} />
                </ChairmanImage>
            </ChairmanContainer>
            <ImageContainer>
                <TextImageContainer>
                    <TextImage image={text} />
                </TextImageContainer>
                <CanImageContainer>
                    <CanImage image={cans} />
                    <Can1Rollover 
                        onMouseOver={() => setActive(0)} 
                        onMouseOut={() => setActive(undefined)}
                        onClick={() => scrollTo('section-1')} />
                    <Can2Rollover 
                        onMouseOver={() => setActive(1)} 
                        onMouseOut={() => setActive(undefined)}
                        onClick={() => scrollTo('section-2')}  />
                    <Can3Rollover 
                        onMouseOver={() => setActive(2)} 
                        onMouseOut={() => setActive(undefined)}
                        onClick={() => scrollTo('section-3')}  />
                </CanImageContainer>
                <HechoImageContainer>
                    <HechoImage className="hecho-LuchadorImage" image={hecho} />
                </HechoImageContainer>
                <Spacer />
            </ImageContainer>
        </StyledHome>
    )
}

const StyledHome = styled(FullScreenContainer)`
    background-position: top right;
    height: 100vh;

    ${aspectRatioMediaQueries("homeBreak")`
        padding-left: 80px;
    `};

    ${mediaQueries("tablet")`
        padding-left: 0;
    `};
`
const ImageContainer = styled(FullScreenComponent)`
    align-items: flex-end;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    transform: translate(-50%);
    width: auto;

    ${aspectRatioMediaQueries("homeBreak")`
        max-width: 100%;
    `};

    ${mediaQueries("tablet")`
        padding-left: 0;
        padding-right: 0;
    `};
`

const CanImageContainer = styled.div`
    position: relative;
    max-width: 100%;
`

const ChairmanContainer = styled.div`
    align-items: flex-end;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
`

const ChairmanImage = styled.div`
    align-items: flex-end;
    display: flex;
    height: 100vh;
    position: relative;
    max-height: 990px;
    width: auto;
`

const RolloverImage = styled(LuchadorImage)`
    left: 44%;
    position: absolute;
    top: 38.75%;
    width: 37.3%;

    &.active {
        opacity: 1;
    }
    &.inactive {
        opacity: 0;
    }
`

const CanImage = styled(LuchadorImage)`
    flex: 0 1 75%;
    margin-bottom: 5vh;
    max-height: 70vh;
    max-width: calc(70vh / 0.798);
    justify-content: center;
    position: relative;
    width: 926px;
    object-position: top;
    z-index: 1;

    ${aspectRatioMediaQueries("homeBreak")`
        max-width: 100%;
    `};

    ${aspectRatioMediaQueries("homeBreak")`
        margin-bottom: 0;
        transform: translateX(2.25%);
    `};

`

const CanRolloverHitbox = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    width: 33.33%;
    cursor: pointer;
    z-index: 1;
`
const Can1Rollover = styled(CanRolloverHitbox)`
    left: 0;
`
const Can2Rollover = styled(CanRolloverHitbox)`
    left: 33.33%;
    width: 30%;
`
const Can3Rollover = styled(CanRolloverHitbox)`
    left: 63.33%;
`

const Spacer = styled.div`
    flex: 1 1 0%;
    width: 100%;

    ${aspectRatioMediaQueries("homeBreak")`
        flex: 0;
    `};
`

const TextImageContainer = styled.div`
    flex: 1 1 25%;
    height: 25%;
    min-height: 100px;
    position: relative;
    width: 100%;
    z-index: 0;

    ${aspectRatioMediaQueries("homeBreak")`
        display: flex;
        flex: 1;
    `};
`

const HechoImageContainer = styled.div`
    flex: 0;
    height: 0%;
    position: relative;
    width: 100%;
    z-index: 0;

    ${aspectRatioMediaQueries("homeBreak")`
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    `};

`
const HechoImage = styled(LuchadorImage)`
    top: 0;
    left: -5vh;
    position: absolute;
    max-height: 25vh;
    height: 25vh;
    object-position: bottom;
    transform: translate(-80%, calc(-100% - 5vh));
    max-width: 240px;
    width: auto;
    
    ${aspectRatioMediaQueries("homeBreak")`
        position: relative;
        left: initial;
        transform: none;
        max-height: calc(100% - 40px);
        max-width: 35vw;
        height: auto;
        margin: 20px;
    `};
`

const TextImage = styled(LuchadorImage)`
    bottom: 0;
    left: 0;
    position: absolute;
    max-height: 33vh;
    height: 33vh;
    object-position: bottom;
    transform: translate(-50%, 25%);
    max-width: 691px;
    width: auto;
    
    @media (max-width: ${breakpoints['maxHeadline']}px) { 
        ${aspectRatioMediaQueries("homeLogoBreak")`
            height: 27vh;
            transform: translate(calc(87px + (-1 * ((100vw - 710px) / 2))), 10%)
        `};
    };
    
    ${aspectRatioMediaQueries("homeBreak")`
        position: relative;
        bottom: initial;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        height: auto;
        transform: translate(-2.5%, 5%);
    `};
`

export default Home
