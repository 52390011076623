import React from "react"
import styled from "styled-components"

interface ComponentProps {
  children: any,
  className?: string,
}

const StyledFullScreenComponent = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 40px;
`

const FullScreenComponent = ({ children, className }:ComponentProps) => (
  <StyledFullScreenComponent className={className}>
    {children}
  </StyledFullScreenComponent>
)

export default FullScreenComponent
