import React from "react"
import styled from "styled-components"
import { StyledH1, StyledP } from "../../styles/typography"

const Headline = styled(StyledH1)`
  font-size: 2.75em;
  color: black;
  text-align: center;
`
const BlockSpan = styled.span`
  display: flex;
  align-items: center;
`
const StyledLineOne = styled.span`
  font-family: 'Champion Heavyweight';
  letter-spacing: 0.1em;
`

const SectionFiveHeadline = () => (
    <Headline>
      <BlockSpan>
        <StyledLineOne>Don’t miss <br/> our El Luchador <br/> in-store display!</StyledLineOne>
      </BlockSpan>
    </Headline>
)

export default SectionFiveHeadline
