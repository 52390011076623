import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SectionFiveQueryQuery } from "../../graphql"
import { mediaQueries } from "../../styles/mediaQueries"
import { StyledP } from "../../styles/typography"
import { useWindowResize } from "../../utilities/hooks"
import { getTransformStyleBasedOnBoxSize } from "../../utilities/luchador-utils"
import FullScreenComponent from '../full-screen-component'
import FullScreenContainer from '../full-screen-container'
import LuchadorImage from '../luchador-image'
import SectionCopy from "../section-copy"
import SectionFiveHeadline from "./section-5-headline"

interface SectionFiveProps {
}

const SectionFive = ({}: SectionFiveProps) => {
    const windowSize = useWindowResize();
    const { background, boxes }: SectionFiveQueryQuery = useStaticQuery(
        graphql`
            query SectionFiveQuery {
                background: file(
                    relativePath: { eq: "section-5/PAGE_5_BG_x2.jpg"}
                ) {
                    ...backgroundImage
                },
                boxes: file(
                    relativePath: { eq: "section-5/PAGE_5_4PACKS_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 3331) {
                            src
                        }
                    }
                }
            }
        `,
    )

    const transformStyle = getTransformStyleBasedOnBoxSize(
        -3,
        1.5,
        1000,
        800,
        1200,
        853,
        windowSize.width,
        windowSize.height
    )

    return (
        <StyledSectionFive id="section-5" backgroundImage={background.childImageSharp.fluid.src}>
            <ImageContainer>
                <div style={transformStyle}>
                    <CopyContainer>
                        <SectionCopy
                            headlineComponent={<SectionFiveHeadline />}
                        />
                    </CopyContainer>
                    <CanImage image={boxes} />
                </div>
            </ImageContainer>
        </StyledSectionFive>
    )
}

const StyledSectionFive = styled(FullScreenContainer)`
    display: flex;
    align-items: center;
    height: auto;
    justify-content: center;
    min-height: 100vh;
`

const ImageContainer = styled(FullScreenComponent)`
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding: 58px 40px;
    position: relative;
    width: calc(100% - 80px);
    left: 40px;

    ${mediaQueries("tablet")`
        width: 100%;
        left: 0;
        padding: 58px 20px;
    `};
`

const CopyContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
`

const CanImage = styled(LuchadorImage)`
    max-height: 58.75vh;
    transform: translateX(7%);
`

export default SectionFive
