import React from "react"
import styled from "styled-components"
import { mediaQueries } from "../../styles/mediaQueries"
import { StyledH1, StyledP } from "../../styles/typography"
import { useWindowResize } from "../../utilities/hooks"

const Headline = styled(StyledH1)`
  font-size: 2.45em;
  color: black;
  margin: 0;
`
const BlockSpan = styled.span`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
`
const ParagraphBlockSpan = styled(BlockSpan)`
  flex-direction: column;
`
const StarBlockSpan = styled(BlockSpan)`
  margin: 0.45em 0.2em 0.6em;
`
const StyledStars = styled.span`
  font-family: 'Stars';
  font-size: 0.5em;
  letter-spacing: 0;
  text-align: center;

  &:first-child {
    margin-right: -0;
  }

  &:last-child {
    margin-left: 0;
  }
`
const StyledLineOne = styled.span`
  font-family: 'Champion Heavyweight';

  ${mediaQueries("tablet")`
    font-size: calc((100vw - 40px) * 0.0925);
  `};
`
const StyledLineTwo = styled.span`
  font-family: 'Champion Heavyweight';

  ${mediaQueries("tablet")`
  font-size: calc((100vw - 40px) * 0.17);
  `};
`

const SectionFiveHeadline = () => {
  const windowSize = useWindowResize();
  const isSmall = windowSize.width <= 768;
  return (
    <Headline>
      <StarBlockSpan>
        {isSmall
          ? (
            <>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            </>
          )
          : (
            <>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            </>
          )
        }
      </StarBlockSpan>
      <ParagraphBlockSpan>
        <StyledLineOne>single-flavor{isSmall ? '' : ' four packs'}</StyledLineOne>
        {isSmall ? <StyledLineTwo>4-Packs</StyledLineTwo> : null}
      </ParagraphBlockSpan>
      <StarBlockSpan>
        {isSmall
          ? (
            <>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            </>
          )
          : (
            <>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            <StyledStars>w</StyledStars>
            </>
          )
        }
      </StarBlockSpan>
    </Headline>
)}

export default SectionFiveHeadline
