import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SectionFourQueryQuery } from "../../graphql"
import { breakpoints, mediaQueries } from "../../styles/mediaQueries"
import { StyledP } from "../../styles/typography"
import { useWindowResize } from "../../utilities/hooks"
import FullScreenComponent from '../full-screen-component'
import FullScreenContainer from '../full-screen-container'
import LuchadorImage from '../luchador-image'

interface SectionFourProps {
}

const SectionFour = ({}: SectionFourProps) => {
    const windowSize = useWindowResize();
    const { background, cans }: SectionFourQueryQuery = useStaticQuery(
        graphql`
            query SectionFourQuery {
                background: file(
                    relativePath: { eq: "section-4/PAGE_4_BG_x2.jpg"}
                ) {
                    ...backgroundImage
                },
                cans: file(
                    relativePath: { eq: "section-4/PAGE_4_3DRINKS_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 2390) {
                            src
                        }
                    }
                },
            }
        `,
    )

    const isSmall = windowSize.width <= breakpoints.tablet;

    return (
        <StyledSectionFour id="section-4" backgroundImage={background.childImageSharp.fluid.src}>
            <StyledComponent>
                <Container>
                    <CopyWrapper>
                        <Paragraph color="black">
                            <Emphasis>El Luchador</Emphasis><br /> Can-crafted Cocktails
                        </Paragraph>
                        <BodyParagraph color="black">
                            El Luchador ultra-premium sparkling cocktails are crafted with 100% Blue Agave Tequila and ready to{isSmall ? '' : <br/>}
                            enjoy whenever you are. Our can-crafted cocktails are hecho en Mexico (Made in Mexico), which insures{isSmall ? '' : <br/>}
                            you’re drinking 100% Blue Agave tequila. You’ll find our distillery registration number (NOM) printed{isSmall ? '' : <br/>}
                            on every can for the ultimate seal of authenticity.
                        </BodyParagraph>
                        <BodyParagraph color="black">
                            Our classic El Luchador cocktails are made with all natural ingredients. They can be chilled and {isSmall ? '' : <br/>}
                            consumed directly from the can or poured over ice in your favorite glassware for even greater enjoyment
                        </BodyParagraph>
                    </CopyWrapper>
                    <ImageWrapper>
                        <CanImage image={cans} />
                    </ImageWrapper>
                </Container>
            </StyledComponent>
        </StyledSectionFour>
    )
}

const Container = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Emphasis = styled.span`
    font-weight: bold;
    font-size: 1.5em;
`
const CopyWrapper = styled.div`
`
const ImageWrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: center;;
`

const StyledSectionFour = styled(FullScreenContainer)`
    display: flex;
    align-items: center;
    height: auto;
    justify-content: center;
    min-height: 100vh;
    
`

const StyledComponent = styled(FullScreenComponent)`
    align-items: stretch;
    display: inline-flex;
    flex-direction: column;
    justify-content: stretch;
    left: 20px;
    padding: 58px 40px;
    position: relative;
    width: calc(100% - 80px);
    left: 40px;
    min-height: 100vh;

    ${mediaQueries("tablet")`
        width: 100%;
        left: 0;
    `};
`

const Paragraph = styled(StyledP)`
    font-size: 26.25px;
    line-height: 1.4em;
    max-width: 940px;
    width: 100%;
    margin: 0;

    &:not(:last-child) {
        margin-bottom: 0.55em;
    }

    ${mediaQueries("maxHeadline")`
        font-size: 1.75vw;
    `};

    ${mediaQueries("tablet")`
        font-size: 24px;
        margin-bottom: 1em;
    `};
`
const BodyParagraph = styled(Paragraph)`
    ${mediaQueries("tablet")`
        font-size: 20px;
    `};
`

const CanImage = styled(LuchadorImage)`
    max-height: 53vh;
`


export default SectionFour
