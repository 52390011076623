import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SectionOneQueryQuery } from "../../graphql"
import { mediaQueries } from "../../styles/mediaQueries"
import { useWindowResize } from "../../utilities/hooks"
import FullScreenComponent from '../full-screen-component'
import FullScreenContainer from '../full-screen-container'
import LuchadorImage from '../luchador-image'
import SectionCanCopy from "../section-can-copy"
import SectionCopy from "../section-copy"
import SectionOneHeadline from "./section-1-headline"

interface SectionOneProps {
}

const SectionOne = ({}: SectionOneProps) => {
    const { background, can, overlay }: SectionOneQueryQuery = useStaticQuery(
        graphql`
            query SectionOneQuery {
                background: file(
                    relativePath: { eq: "section-1/PAGE_1_BG_x2.jpg"}
                ) {
                    ...backgroundImage
                },
                can: file(
                    relativePath: { eq: "section-1/PAGE_1_GRN_CAN_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 709) {
                            src
                        }
                    }
                },
                overlay: file(
                    relativePath: { eq: "overlay1.jpg"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1500) {
                            src
                        }
                    }
                },
            }
        `,
    )

    return (
        <StyledSectionOne id="section-1" backgroundImage={background.childImageSharp.fluid.src}>
            <SectionCanCopy
                image={can}
                headlineComponent={<SectionOneHeadline />}
                copy={
                    `Our Classic Lime Margarita contains all natural<br />
                    lime and orange flavors that are significantly <br />
                    enhanced by the velvety, agave-driven <br />
                    flavors of El Luchador.`
                }
                offsetLeft={-9.5}
                offsetTop={5}
                textOffsetLeft={3.5}
                textOffsetTop={-5}
            />
        </StyledSectionOne>
    )
}

const StyledSectionOne = styled(FullScreenContainer)`
    background-position: bottom right;
    display: flex;
    align-items: center;
    justify-content: center;
`


export default SectionOne
