import React from "react"
import Home from "../components/home/home"
import Layout from "../components/layout"
import SectionOne from "../components/section-1/section-1"
import SectionTwo from "../components/section-2/section-2"
import SectionThree from "../components/section-3/section-3"
import SectionFour from "../components/section-4/section-4"
import SectionFive from "../components/section-5/section-5"
import SectionSix from "../components/section-6/section-6"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <Home />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
    </Layout>
  )
}

export default IndexPage
