import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SectionThreeQueryQuery } from "../../graphql"
import { mediaQueries } from "../../styles/mediaQueries"
import FullScreenComponent from '../full-screen-component'
import FullScreenContainer from '../full-screen-container'
import LuchadorImage from '../luchador-image'
import SectionCanCopy from "../section-can-copy"
import SectionCopy from "../section-copy"
import SectionThreeHeadline from "./section-3-headline"

interface SectionThreeProps {
}

const SectionThree = ({}: SectionThreeProps) => {
    const { background, cans }: SectionThreeQueryQuery = useStaticQuery(
        graphql`
            query SectionThreeQuery {
                background: file(
                    relativePath: { eq: "section-3/PAGE_3_BG_x2.jpg"}
                ) {
                    ...backgroundImage
                },
                cans: file(
                    relativePath: { eq: "section-3/PAGE_3_CAN_ONLY_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 707) {
                            src
                        }
                    }
                },
            }
        `,
    )

    return (
        <StyledSectionThree id="section-3" backgroundImage={background.childImageSharp.fluid.src}>
            <SectionCanCopy
                image={cans}
                headlineComponent={<SectionThreeHeadline />}
                copy={
                    `Vibrant describes our Blood Orange Margarita. <br />
                    the classic Margarita crafted with El Luchador <br />
                    and exotic natural blood orange flavors <br />
                    enhanced with a touch of lime.`
                }
                offsetLeft={-8}
                offsetTop={5}
                textOffsetLeft={3.5}
                textOffsetTop={-5}
            />
        </StyledSectionThree>
    )
}

const StyledSectionThree = styled(FullScreenContainer)`
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        ${mediaQueries("tablet")`
            transform: translateX(7.5%);
        `};
    }
`

export default SectionThree
