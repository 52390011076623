import React from "react"
import styled from "styled-components"
import { StyledH1, StyledP } from "../../styles/typography"

const Headline = styled(StyledH1)`
  color: black;
`
const BlockSpan = styled.span`
  display: flex;
  align-items: center;
`
const StyledStars = styled.span`
  font-family: 'Stars';
  font-size: 0.34em;
  margin-top: 0.25em;
  letter-spacing: 1.1em;
  transform: translateY(-30%);

  &:first-child {
    margin-right: -0.25em;
  }

  &:last-child {
    margin-left: 0.25em;
  }
`
const StyledLineOne = styled.span`
  font-family: 'Champion Featherweight';
  font-size: 0.9em;
  letter-spacing: 0.12em;
  margin: auto;
  transform: translateX(-3%);
  -webkit-font-smoothing: subpixel-antialiased;
`
const StyledLineTwo = styled.span`
  font-family: 'Champion Heavyweight';
  font-size: 1.15em;
  letter-spacing: 0.01em;
  margin: auto;
`

const SectionOneHeadline = () => (
    <Headline>
      <BlockSpan>
        <StyledLineOne>Sparkling</StyledLineOne>
      </BlockSpan>
      <BlockSpan>
        <StyledLineTwo>Paloma</StyledLineTwo>
      </BlockSpan>
      <BlockSpan>
        <StyledStars>wwwwwwwwwwww</StyledStars>
      </BlockSpan>
    </Headline>
)

export default SectionOneHeadline
