import React from "react"
import styled from "styled-components"
import { breakpoints, mediaQueries } from "../styles/mediaQueries"
import { StyledP } from "../styles/typography"
import { useWindowResize } from "../utilities/hooks"
import { getTransformStyleBasedOnBoxSize } from "../utilities/luchador-utils"
import FullScreenComponent from "./full-screen-component"
import LuchadorImage from "./luchador-image"
import SectionOneHeadline from "./section-3/section-3-headline"
import SectionCopy from "./section-copy"

interface SectionCanCopyProps {
  copy?: string,
  headlineComponent?: JSX.Element,
  image?: any,
  color?: string,
  reverseOrder?: boolean,
  offsetLeft?: number,
  offsetTop?: number,
  textOffsetLeft?: number,
  textOffsetTop?: number,
  largerFont?: boolean,
}

const Container = styled.div`
    position: relative;
    left: 40px;
    width: calc(100vw - 80px);
    max-width: 1160px;

    ${mediaQueries("tablet")`
        left: 0px;
        width: 100%;
    `};
`

const ImageContainer = styled(FullScreenComponent)`
    align-items: center;
    display: inline-flex;
    justify-content: center;
    position: relative;
    flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
    max-height: calc(71.5vh + 80px);

    ${mediaQueries("tablet")`
        max-height: none;
        flex-direction: column-reverse;
        padding: 64px 40px;

    `};
`

const CanImage = styled(LuchadorImage)`
    max-width: calc((100% - 120px) * 0.33);
    max-height: 71.5vh;

    ${mediaQueries("tablet")`
        max-width: 75%;
        flex-direction: column-reverse;
        transform: translateX(3%);
    `};
`

const StyledSectionOneCopy = styled(SectionCopy)`
    width: auto;

    ${mediaQueries("tablet")`
        transform: translate(0,0);
        width: auto;
    `};

    h1 {
        font-size: ${props => props.largerFont ? '66.5px' : '66.5px'};
        margin: 0;

        ${mediaQueries("maxHeadline")`
            font-size: 4.43875vw;
        `};

        ${mediaQueries("tablet")`
            font-size: 8vw;
        `};
    }

    p {
        letter-spacing: 0.0075em;
        margin: 0;
        margin-right: 4%;
        font-size: 37px;
        transform: translateY(8%);

        ${mediaQueries("maxHeadline")`
            font-size: 2.47vw;
        `};

        ${mediaQueries("tablet")`
            font-size: 4.5vw;
            margin-bottom: 1em;
        `};
    }
`

const SectionCanCopy = (props:SectionCanCopyProps) => {
    const {height, width} = useWindowResize();

  const transformStyle = getTransformStyleBasedOnBoxSize(
        props.offsetLeft || 0,
        props.offsetTop || 0,
        1000,
        800,
        1200,
        853,
        width,
        height
    )

    const textTransformStyle = width > breakpoints.tablet 
        ? {
            transform: `translate(${props.textOffsetLeft || 0}%, ${props.textOffsetTop || 0}%)`
        } : {}

    return (
        <Container style={transformStyle}>
            <ImageContainer reverse={props.reverseOrder} largerFont={props.largerFont}>
                <CanImage image={props.image} />
                <div style={textTransformStyle}>
                    <StyledSectionOneCopy
                        headlineComponent={props.headlineComponent }
                        copy={props.copy}
                        color={props.color}
                    />
                </div>
            </ImageContainer>
        </Container>
    )
}

export default SectionCanCopy
