import { copyFile } from "fs"
import { graphql, useStaticQuery } from "gatsby"
import React, { useRef } from "react"
import styled from "styled-components"
import { SectionSixQueryQuery } from "../../graphql"
import { breakpoints, mediaQueries } from "../../styles/mediaQueries"
import { StyledH1, StyledP } from "../../styles/typography"
import { useWindowResize } from "../../utilities/hooks"
import FullScreenComponent from '../full-screen-component'
import FullScreenContainer from '../full-screen-container'
import LuchadorImage from '../luchador-image'
import Modal from "../modal"
import SectionCopy from "../section-copy"
import SectionSixHeadline from "./section-6-headline"

interface SectionSixProps {
}

const SectionSix = ({}: SectionSixProps) => {
    const greenRollover = React.createRef<SVGImageElement>()
    const whiteRollover = React.createRef<SVGImageElement>()
    const redRollover = React.createRef<SVGImageElement>()
    const { 
        background, 
        modalBackround,
        box, 
        boxRollover, 
        boxPopupImage,
        boxStack, 
        greenBoxRollover, 
        greenBoxPopupImage, 
        whiteBoxRollover, 
        whiteBoxPopupImage, 
        redBoxRollover,
        redBoxPopupImage, 
    }: SectionSixQueryQuery = useStaticQuery(
        graphql`
            query SectionSixQuery {
                background: file(
                    relativePath: { eq: "section-6/PAGE_6_BG_x2.jpg"}
                ) {
                    ...backgroundImage
                },
                modalBackround: file(
                    relativePath: { eq: "section-6/modals/PAGE_6_POPUP_BG_x2.jpg"}
                ) {
                    ...backgroundImage
                },
                box: file(
                    relativePath: { eq: "section-6/PAGE_6_VARIETY_CASE_RGB_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1620) {
                            src
                        }
                    }
                },
                boxRollover: file(
                    relativePath: { eq: "section-6/PAGE_6_VARIETY_CASE_ROLLOVER_BW_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1620) {
                            src
                            presentationWidth
                            presentationHeight
                        }
                    }
                },
                boxPopupImage: file(
                    relativePath: { eq: "section-6/modals/PAGE_6_POPUP_VARIETY_CASE_DRNK_4PK_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 3854) {
                            src
                        }
                    }
                },
                greenBoxRollover: file(
                    relativePath: { eq: "section-6/PAGE_6_STACK_GREEN_CASE_ROLLOVER_BW_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1323) {
                            src
                            presentationWidth
                            presentationHeight
                        }
                    }
                },
                greenBoxPopupImage: file(
                    relativePath: { eq: "section-6/modals/PAGE_6_POPUP_GRN_CASE_DRNK_4PK_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 2930) {
                            src
                        }
                    }
                },
                whiteBoxRollover: file(
                    relativePath: { eq: "section-6/PAGE_6_STACK_WHITE_CASE_ROLLOVER_BW_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1323) {
                            src
                            presentationWidth
                            presentationHeight
                        }
                    }
                },
                whiteBoxPopupImage: file(
                    relativePath: { eq: "section-6/modals/PAGE_6_POPUP_WHT_CASE_DRNK_4PK_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 2911) {
                            src
                        }
                    }
                },
                redBoxRollover: file(
                    relativePath: { eq: "section-6/PAGE_6_STACK_RED_CASE_ROLLOVER_BW_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1323) {
                            src
                            presentationWidth
                            presentationHeight
                        }
                    }
                },
                redBoxPopupImage: file(
                    relativePath: { eq: "section-6/modals/PAGE_6_POPUP_RED_CASE_DRNK_4PK_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 3661) {
                            src
                        }
                    }
                },
                boxStack: file(
                    relativePath: { eq: "section-6/PAGE_6_STACK_3CASES_RGB_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1603) {
                            src
                        }
                    }
                },
            }
        `,
    )

    const [activeBox, setActiveBox] = React.useState("")
    const [activeModal, setActiveModal] = React.useState("")
    const windowSize = useWindowResize();
    const isSmall = windowSize.width <= breakpoints.tablet;

    const breakIfLarge = isSmall ? '' : '<br />';
    const modals = [
        {
            headline: `Each Variety Case contains${breakIfLarge} 6 packs of 4  x 12 FL. OZ. Cans`,
            image: boxPopupImage,
            key: 'variety',
            className: 'popup-image popup-image-variety'
        },
        {
            headline: `Each Case contains 6${breakIfLarge} Classic Lime Margarita 4  -  Packs`,
            image: greenBoxPopupImage,
            key: 'green',
            className: 'popup-image popup-image-green',
            review: {
                headline: 'El Luchador Sparkling Lime Margarita, Mexico ($4.99)',
                description: `The nose attests to a tart yet sweet lime character while unmasking the agave freshness. The fizz is gentle and allows just the right level of citrusy sweetness to release. This is an unprecedented tequila-based ready-to-drink cocktail with superb flavor components and an ABV of 8%.`,
                rating: 91,
                attribution: 'Meridith May, TASTING PANEL MAGAZINE',
            }
        },
        {
            headline: `Each Case contains 6${breakIfLarge} sparkling paloma 4  -  Packs`,
            image: whiteBoxPopupImage,
            key: 'white',
            className: 'popup-image popup-image-white',
            review: {
                headline: 'El Luchador Sparkling Paloma, Mexico ($4.99)',
                description: 'This prom-dress-pink beauty engages with scents of rose petals, vanilla, and grapefruit. The citrus is enveloped by hibiscus, a noteworthy floral tone that adds dimension to this satisfying and delicious cocktail.',
                rating: 92,
                attribution: 'Meridith May, TASTING PANEL MAGAZINE',
            }
        },
        {
            headline: `Each Case contains 6${breakIfLarge} Blood orange Margarita 4  -  Packs`,
            image: redBoxPopupImage,
            key: 'red',
            className: 'popup-image popup-image-red',
            review: {
                headline: 'El Luchador Sparkling Blood Orange Margarita, Mexico ($4.99)',
                description: 'A neon-orange hue akin to a Southwestern sunset greets the eyes as citrus perfumes the glass with a pungent ripeness. The bright flavors of just-squeezed orange are streamlined by a hint of vanilla, which keeps the drink from being cloying. There’s a lingering flavor of orange zest without any bitterness or aftertaste. Clean and refreshing.',
                rating: 90,
                attribution: 'Meridith May, TASTING PANEL MAGAZINE',
            }
        }
    ];

    const renderBoxImage = () => 
        <BoxImageContainer>
            <div 
                onMouseOver={() => {
                    setActiveBox('variety')
                }}
                onMouseOut={() => {
                    setActiveBox(undefined)
                }}
                onClick={() => {
                    setActiveModal('variety')
                }}
            >
                <BoxImage 
                    image={box} />
            </div>
            <BoxImageRollover 
                className={activeBox === 'variety' ? 'active' : ''}
                image={boxRollover} />
        </BoxImageContainer>

    return (
        <StyledSectionSix id="section-6" backgroundImage={background.childImageSharp.fluid.src}>
            <ImageContainer>
                <Left>
                    <CopyContainer>
                        <StyledSectionCopy
                            color={'black'}
                            headlineComponent={<SectionSixHeadline />}
                            copy="El Luchador RTD cocktails are unmistakable in <br/>
                                their green, white, and red and multi-colored <br/>
                                24-can cases."
                        />
                    </CopyContainer>
                    {isSmall ? undefined : renderBoxImage()}
                </Left>
                <Right>
                    {isSmall ? renderBoxImage() : undefined}
                    <BoxSvg 
                        viewBox="0 0 1603 1766"
                        height="100%"
                        width="100%" 
                        preserveAspectRatio="none"
                        version="1.1"
                    >
                        <image 
                            height="100%"
                            width="100%" 
                            preserveAspectRatio="none"
                            xlinkHref={boxStack.childImageSharp.fluid.src}
                        />
                        <StyledSvgImage 
                            height="41%"
                            width="82.8%" 
                            x="-0.2%"
                            preserveAspectRatio="none"
                            xlinkHref={greenBoxRollover.childImageSharp.fluid.src}
                            ref={greenRollover}
                            className={activeBox === 'green' ? 'active' : ''}
                        />
                        <StyledSvgImage 
                            height="38.9%"
                            width="82.4%"
                            y="31%"
                            x="0.25%"
                            preserveAspectRatio="none"
                            xlinkHref={whiteBoxRollover.childImageSharp.fluid.src}
                            ref={whiteRollover}
                            className={activeBox === 'white' ? 'active' : ''}
                        />
                        <StyledSvgImage 
                            height="42.8%"
                            width="81.7%" 
                            y="56.25%"
                            x="0.85%"
                            preserveAspectRatio="none"
                            xlinkHref={redBoxRollover.childImageSharp.fluid.src}
                            ref={redRollover}
                            className={activeBox === 'red' ? 'active' : ''}
                        />
                        <Path
                            className="path"
                            id="green-box-hit"
                            data-placement="top" data-desc="greenBox"
                            d="M 0,100 848,0 1325,105 1325,570 460,750 5,585 Z"
                            fill="rgba(0,0,0,0)" 
                            onMouseOver={() => {
                                setActiveBox('green')
                            }}
                            onMouseOut={() => {
                                setActiveBox(undefined)
                            }} 
                            onClick={() => {
                                setActiveModal('green')
                            }} />
                        <Path
                            className="path"
                            id="white-box-hit"
                            data-placement="top" data-desc="greenBox"
                            d="M 5,585 460,750 1325,570 1325,1035 460,1275 10,1085 5,585 Z"
                            fill="rgba(0,0,0,0)" 
                            onMouseOver={() => {
                                setActiveBox('white')
                            }}
                            onMouseOut={() => {
                                setActiveBox(undefined)
                            }} 
                            onClick={() => {
                                setActiveModal('white')
                            }} />
                        <Path
                            className="path"
                            id="red-box-hit"
                            data-placement="top" data-desc="greenBox"
                            d="M 10,1085 460,1275 1325,1035 1325,1495 460,1810 15,1585 10,1085 Z"
                            fill="rgba(0,0,0,0)" 
                            onMouseOver={() => {
                                setActiveBox('red')
                            }}
                            onMouseOut={() => {
                                setActiveBox(undefined)
                            }} 
                            onClick={() => {
                                setActiveModal('red')
                            }} />
                    </BoxSvg>
                </Right>
            </ImageContainer>
            {modals.map(({headline, image, key, review, className}) => {
                return (
                    <Modal key={key} isOpen={key === activeModal} onClose={() => setActiveModal(undefined)}>
                        <StyledModalSection backgroundImage={modalBackround.childImageSharp.fluid.src}>
                            <ModalHeadline dangerouslySetInnerHTML={{__html: headline}} />
                            <ModalImage className={className} >
                                <ModalLuchadorImageContainer>
                                    <ModalLuchadorImage image={image} />
                                </ModalLuchadorImageContainer>
                            </ModalImage>
                            {review &&
                                <ModalReview>
                                    <ReviewHeadline>{review.headline}</ReviewHeadline>
                                    <ReviewDescription dangerouslySetInnerHTML={{__html: `"` + review.description + " <span style='font-weight: bold'>" + review.rating + "</span>" + `"` }} />
                                    <ReviewAttribution>—{review.attribution}</ReviewAttribution>
                                </ModalReview>
                            }
                        </StyledModalSection>
                    </Modal>
                )
            })}
        </StyledSectionSix>
    )
}

const StyledSectionSix = styled(FullScreenContainer)`
    display: flex;
    align-items: center;
    height: auto;
    justify-content: center;
    min-height: 100vh;
    max-width: 100%;
`

const StyledModalSection = styled(FullScreenContainer)`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 100%;
    max-height: calc(100vh - 80px);
    height: auto;
    min-height: auto;
    overflow: hidden;
    width: calc(100vw - 80px);
    padding: 20px;
    overflow: scroll;

    ${mediaQueries("tablet")`
        width: calc(100vw - 48px);
    `};
`

const ImageContainer = styled(FullScreenComponent)`
    align-items: center;
    display: inline-flex;
    justify-content: center;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    padding: 76px 40px 58px;
    position: relative;
    min-height: 100vh;
    width: calc(100% - 80px);
    left: 40px;
    max-width: 1600px;

    ${mediaQueries("tablet")`
        width: 100%;
        left: 0;
        flex-direction: column;
        padding: 76px 20px 58px;
    `};
`

const CopyContainer = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    margin-bottom: 40px;
`

const Left = styled.div`
    display: flex;
    flex: 0 0 50%;
    flex-direction: column;

    ${mediaQueries("tablet")`
        width: 100%;
        margin-bottom: 40px;
    `};
`

const Right = styled.div`
    flex: 0 0 50%;
    position: relative;

    ${mediaQueries("tablet")`
        width: 100%;
        margin-bottom: 20%;
    `};
`

const BoxImageContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 95%;
    transform: translate(16%,4%);

    ${mediaQueries("tablet")`
    width: 80%;
    position: absolute;
        bottom: 0;
        z-index: 1;
        transform: translate(42%, 32%);
    `};
`

const BoxSvg = styled.svg`
    width: 95%;
    height: auto;
    transform: translateY(1%);

    ${mediaQueries("tablet")`
        width: 80%;
        transform: translate(0,0%);
    `};

`

const BoxImage = styled(LuchadorImage)`
    cursor: pointer;

`

const BoxImageRollover = styled(LuchadorImage)`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;

    &.active {
        opacity: 1
    }

`

const StyledSvgImage = styled.image`
    opacity: 0;
    &.active {
        opacity: 1;
    }
`

const Path = styled.path`
    cursor: pointer;
`

const StyledSectionCopy = styled(SectionCopy)`
    transform: translate(4%,10%);

    ${mediaQueries("tablet")`
        transform: translate(0,0);
    `};

    h1 {
        font-size: 37.5px;
        line-height: 1.1em;

        ${mediaQueries("maxHeadline")`
            font-size: 2.5vw;
        `};

        ${mediaQueries("tablet")`
            font-size: 6vw;
        `};
    }
    p {
        font-size: 28.5px;

        ${mediaQueries("maxHeadline")`
            font-size: 1.9vw;
            letter-spacing: 0.05em;
        `};

        ${mediaQueries("tablet")`
            font-size: 4.3vw;
        `};
    }
`

const ModalHeadline = styled(StyledH1)`
  color: black;
  font-size: 36px;
  font-family: 'Champion Heavyweight';
  letter-spacing: 0.06em;
  line-height: 1.3em;
  margin: 1em;
  text-align: center;
    flex: 0;
    ${mediaQueries("xl")`
        font-size: calc((80vw * 0.0235));
    `};
  
    ${mediaQueries("medium")`
        font-size: 24px;
    `};
  
    ${mediaQueries("tablet")`
        font-size: 20px;
        margin: 0 0 1em;
    `};
`
const ModalImage = styled.div`
    position: relative;
    width: 100%;

    &.popup-image-green {
        ${mediaQueries("tablet", true)`
            flex: 0 1 calc((100vw - 120px) * 0.5 );
        `};
        ${mediaQueries("tablet")`
            flex: 0 1 calc((100vw - 88px) * 0.5 );
        `};
    }

    &.popup-image-white {
        ${mediaQueries("tablet", true)`
            flex: 0 1 calc((100vw - 120px) * 0.5189 );
        `};
        ${mediaQueries("tablet")`
            flex: 0 1 calc((100vw - 88px) * 0.5189 );
        `};
    }

    &.popup-image-red {
        ${mediaQueries("tablet", true)`
            flex: 0 1 calc((100vw - 120px) * 0.429 );
        `};
        ${mediaQueries("tablet")`
            flex: 0 1 calc((100vw - 88px) * 0.429 );
        `};
    }

    &.popup-image-variety {
        ${mediaQueries("tablet", true)`
            flex: 0 1 calc((100vw - 120px) * 0.338 );
        `};
        ${mediaQueries("tablet")`
            flex: 0 1 calc((100vw - 88px) * 0.338 );
        `};
    }

    
`
const ModalLuchadorImageContainer = styled.div`
    ${mediaQueries("tablet", true)`
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
    `};
`
const ModalLuchadorImage = styled(LuchadorImage)`
    ${mediaQueries("tablet", true)`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    `};
    object-fit: contain;
`
const ModalReview = styled.div`
    flex: 0;
    width: calc(100% - 180px);
    margin-top: 1em;
    ${mediaQueries("tablet")`
        width: 100%;
    `};
`
const ReviewHeadline = styled(StyledP)`
    color: black;
    font-family: 'Champion Middleweight';
    letter-spacing: 0.025em;
    ${mediaQueries("xl")`
        font-size: calc((100vw - 120px) * 0.022);
    `};
  
    ${mediaQueries("medium")`
        font-size: 24px;
    `};
  
    ${mediaQueries("tablet")`
        font-size: 20px;
    `};

`
const ReviewDescription = styled(StyledP)`
    color: black;
    ${mediaQueries("xl")`
        font-size: calc((100vw - 120px) * 0.022);
    `};
  
    ${mediaQueries("medium")`
        font-size: 24px;
    `};
  
    ${mediaQueries("tablet")`
        font-size: 20px;
    `};

`
const ReviewAttribution = styled(StyledP)`
    color: black;
    font-family: 'Champion Lightweight';
    letter-spacing: 0.025em;
    text-transform: unset;
    ${mediaQueries("xl")`
        font-size: calc((100vw - 120px) * 0.018);
    `};
  
    ${mediaQueries("medium")`
        font-size: 24px;
    `};
  
    ${mediaQueries("tablet")`
        font-size: 20px;
    `};

`

export default SectionSix
