import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { SectionTwoQueryQuery } from "../../graphql"
import { mediaQueries } from "../../styles/mediaQueries"
import FullScreenComponent from '../full-screen-component'
import FullScreenContainer from '../full-screen-container'
import LuchadorImage from '../luchador-image'
import SectionCanCopy from "../section-can-copy"
import SectionCopy from "../section-copy"
import SectionTwoHeadline from "./section-2-headline"

interface SectionTwoProps {
}

const SectionTwo = ({}: SectionTwoProps) => {
    const { background, can, overlay }: SectionTwoQueryQuery = useStaticQuery(
        graphql`
            query SectionTwoQuery {
                background: file(
                    relativePath: { eq: "section-2/PAGE_2_BG_x2.jpg"}
                ) {
                    ...backgroundImage
                },
                can: file(
                    relativePath: { eq: "section-2/PAGE_2_WHT_CAN_x2.png"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 707) {
                            src
                        }
                    }
                },
                overlay: file(
                    relativePath: { eq: "overlay.jpg"}
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1500) {
                            src
                        }
                    }
                },
            }
        `,
    )

    return (
        <StyledSectionTwo id="section-2" backgroundImage={background.childImageSharp.fluid.src}>
            <SectionCanCopy
                image={can}
                headlineComponent={<SectionTwoHeadline />}
                color="black"
                reverseOrder={true}
                copy={
                    `A refreshing Paloma provides the perfect<br />
                    blend of crisp grapefruit and lime <br />
                    flavors expertly balanced with<br />
                    El Luchador Tequila.`
                }
                offsetLeft={16}
                offsetTop={5}
                textOffsetLeft={-7}
                textOffsetTop={-17}
                largerFont={true}
            />
        </StyledSectionTwo>
    )
}

const StyledSectionTwo = styled(FullScreenContainer)`
    display: flex;
    align-items: center;
    justify-content: center;
    
    p {
        font-size: 37px;
        transform: translateY(0%);
        line-height: 1.4em;

        ${mediaQueries("maxHeadline")`
            font-size: 2.6vw;
        `};

        ${mediaQueries("tablet")`
            font-size: 4.5vw;
            margin-bottom: 1em;
        `};
    }
`

const ImageContainer = styled(FullScreenComponent)`
    align-items: center;
    display: inline-flex;
    justify-content: center;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    width: auto;
    max-height: 75vh;
`

const CanImage = styled(LuchadorImage)`
    flex: 0,
`


export default SectionTwo
