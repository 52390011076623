import React from "react"
import styled from "styled-components"
import { mediaQueries } from "../styles/mediaQueries"
import { StyledP } from "../styles/typography"

interface SectionCopyProps {
  copy?: string,
  headlineComponent?: JSX.Element,
  color?: string,
  className?: string,
}

const CopyComponent = styled.div`
  display: table-cell;
`
const StyledHeadlineComponent = styled.div`
  display: table-row;
  width: 1px;
`
const StyledCopyComponent = styled.div`
  display: table-cell;
  width: 1px;

`

const SectionCopy = (props:SectionCopyProps) => {
  const HeadlineComponent = props.headlineComponent || <div />
  return (
    <CopyComponent className={props.className}>
      <StyledHeadlineComponent>
        {HeadlineComponent}
      </StyledHeadlineComponent>
      {props.copy &&
        <StyledCopyComponent>
          <StyledP color={props.color} dangerouslySetInnerHTML={{__html: props.copy}} />
        </StyledCopyComponent>
      }
    </CopyComponent>
  )
}

export default SectionCopy
