import React from "react"
import styled from "styled-components"
import { StyledH1, StyledP } from "../../styles/typography"

const Headline = styled(StyledH1)`
  font-size: 4em;
`
const BlockSpan = styled.span`
  display: flex;
  align-items: center;

  span {
      line-height: 1em;
  }
`
const StyledStars = styled.span`
  font-family: 'Stars';
  font-size: 0.5em;
  margin-bottom: 0.25em;
  letter-spacing: 0.9em;
  margin-top: -0.4em;
  &:first-child {
    margin-right: -0.5em;
  }

  &:last-child {
    margin-left: 0.4em;
  }
`
const StyledLineOne = styled.span`
  font-family: 'Champion Featherweight';
  letter-spacing: 0.12em;
  -webkit-font-smoothing: subpixel-antialiased;
`
const StyledLineTwo = styled.span`
  font-family: 'Champion Welterweight';
  letter-spacing: 0.315em;
`
const StyledLineThree = styled.span`
  font-family: 'Champion Heavyweight';
  font-size: 1.23em;
`

const SectionOneHeadline = () => (
    <Headline>
      <BlockSpan>
        <StyledStars>www</StyledStars>
        <StyledLineOne>Sparkling</StyledLineOne>
        <StyledStars>www</StyledStars>
      </BlockSpan>
      <BlockSpan>
        <StyledLineTwo>Blood Orange</StyledLineTwo>
      </BlockSpan>
      <BlockSpan>
        <StyledLineThree>Margarita</StyledLineThree>
      </BlockSpan>
    </Headline>
)

export default SectionOneHeadline
